<template>
  <div class="card mb-2">
    <div class="card-body pt-2 pb-2">
      <select class="form-select mb-2" v-model="craftType">
        <option value="-1">选择职业</option>
        <option value="0">刻木匠</option>
        <option value="1">锻铁匠</option>
        <option value="2">铸甲匠</option>
        <option value="3">雕金匠</option>
        <option value="4">制革匠</option>
        <option value="5">裁衣匠</option>
        <option value="6">炼金术士</option>
        <option value="7">烹调师</option>
      </select>
      <div class="btn-group" style="width: 100%" role="group">
        <input
          type="radio"
          class="btn-check"
          id="level"
          name="toggle-notebook"
          value="level"
          autocomplete="off"
          v-model="currentSelectedNotebookType"
        />
        <label class="btn btn-outline-secondary" for="level">普通配方</label>

        <input
          type="radio"
          class="btn-check"
          id="special"
          name="toggle-notebook"
          value="special"
          autocomplete="off"
          v-model="currentSelectedNotebookType"
        />
        <label class="btn btn-outline-secondary" for="special">特殊配方</label>
      </div>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item text-muted" v-if="notebookListLevel == null">
        请选择职业...
      </li>
      <template v-else>
        <template v-if="currentSelectedNotebookType == 'level'">
          <a
            href="#"
            class="list-group-item list-group-item-action"
            v-for="notebook in notebookListLevel"
            :key="notebook.id"
            @click.prevent="openNotebook(notebook.id)"
            >{{ notebook.name }}</a
          >
        </template>
        <template v-if="currentSelectedNotebookType == 'special'">
          <template
            v-for="(notebookCat, index) in notebookListSpecial"
            :key="index"
          >
            <button
              type="button"
              class="list-group-item list-group-item-action text-muted"
              v-if="notebookCategoryName[index] != ''"
              @click.prevent="toggleCategory(index)"
            >
              {{ notebookCategoryName[index] }}
            </button>
            <a
              href="#"
              class="list-group-item list-group-item-action"
              v-for="notebook in notebookCat"
              :key="notebook.id"
              @click.prevent="openNotebook(notebook.id)"
              v-show="notebookCategoryShowStatus[index]"
              >{{ notebook.name }}</a
            >
          </template>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ItemSearchNotebook",
  data() {
    return {
      notebookListLevel: null,
      notebookListSpecial: null,
      notebookCategoryName: null,
      craftType: -1,
      currentSelectedNotebookType: "level",
      notebookCategoryShowStatus: {},
    };
  },
  watch: {
    craftType() {
      if (this.craftType != -1) {
        this.getNotebookList();
      } else {
        this.notebookListLevel = null;
        this.notebookListSpecial = null;
        this.notebookCategoryName = null;
      }
    },
  },
  methods: {
    getNotebookList() {
      var self = this;
      this.axios
        .post(this.apiBaseURL + "notebook.php?method=getNotebookList", {
          craftType: this.craftType,
        })
        .then(function (response) {
          if (response.data.success) {
            self.notebookListLevel = new Array();
            self.notebookListSpecial = new Array();
            self.notebookCategoryName = new Object();
            self.notebookCategoryShowStatus = new Object();

            for (let i = 0; i < response.data.data.length; i++) {
              const element = response.data.data[i];

              if (element.categoryID == 0) {
                self.notebookListLevel.push({
                  id: element.id,
                  name: element.name,
                });
              } else {
                if (self.notebookListSpecial[element.categoryID] == null) {
                  self.notebookListSpecial[element.categoryID] = new Array();
                }

                self.notebookListSpecial[element.categoryID].push({
                  id: element.id,
                  name: element.name,
                });
              }

              self.notebookCategoryName[element.categoryID] =
                element.categoryName;
              self.notebookCategoryShowStatus[element.categoryID] = false;
            }
          } else {
            alert("发生错误：" + response.data.error + "，请稍后重试。");
          }
        });
    },
    toggleCategory(id) {
      if (this.notebookCategoryShowStatus[id] == true) {
        this.notebookCategoryShowStatus[id] = false;
      } else {
        this.notebookCategoryShowStatus[id] = true;
      }
    },
    openNotebook(id) {
      this.$parent.openNotebook(id, this.craftType);
    },
  },
};
</script>

<style scoped>
.card {
  overflow-y: hidden;
  max-height: calc(100% - 38px - 8px);
}

.card > .list-group {
  overflow-y: auto;
}
</style>