<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">魔晶石效果</span>
    <div class="d-block" v-for="materia in item.materia" :key="materia.name">
      <span class="badge item-info-badge me-1">{{ materia.baseParamName }}</span>
      <span class="align-middle">+{{ materia.value }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemInfoMateria",
  props: ["item"],
}
</script>