<template>
  <div class="col-auto pe-0">
    <img :src="this.$root.getIconURL(item.icon)" class="float-start item-icon rounded" />
  </div>
  <div class="col mb-2">
    <div class="mb-1">
      <span class="fs-5 me-2">{{ item.name }}</span>
      <!--<div class="float-end">
        <button type="button" class="btn btn-outline-primary btn-sm me-1"><i class="bi bi-star"></i> 收藏</button>
        <button type="button" class="btn btn-outline-secondary btn-sm"><i class="bi bi-star-fill"></i> 已收藏</button>
      </div>-->
    </div>
    <span class="d-block mb-1 small text-muted" v-if="item.description != ''" v-html="description"></span>
    <div class="mb-1">
      <span class="badge text-bg-secondary me-1 align-text-bottom">{{ item.itemUICategory }}</span>
    </div>
    <p class="mb-1">
      <span class="badge text-bg-secondary me-1 align-text-bottom" v-if="item.isUnique == 1">珍稀</span>
      <span class="badge text-bg-secondary me-1 align-text-bottom" v-if="item.isUntradable == 1">独占</span>
      <span class="badge text-bg-secondary me-1 align-text-bottom" v-if="item.isIndisposable == 1">不可丢弃</span>
      <span class="badge text-bg-secondary me-1 align-text-bottom" v-if="item.cooldown > 0">
        复唱时间: {{ getCooldownDisplayStr(item.cooldown, item.canBeHQ) }}
      </span>
      <span class="badge text-bg-secondary me-1 align-text-bottom" v-if="item.addedGameVer != null">
        Patch {{ item.addedGameVer }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "ItemInfoHeader",
  props: ["item"],
  computed: {
    description() {
      return this.item.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
  },
  methods: {
    getCooldownDisplayStr(cooldown, canHQ) {
      var nqCD = cooldown;
      var hqCD = cooldown * 0.9;

      if (canHQ == 1) {
        return this.getCooldownTimeStr(nqCD) + " / " + this.getCooldownTimeStr(hqCD) + ' (HQ)';
      } else {
        return this.getCooldownTimeStr(nqCD);
      }
    },
    getCooldownTimeStr(cooldown) {
      if (cooldown > 60) {
        if (cooldown % 60 > 0) {
          return (
            Math.floor(cooldown / 60) + "分" + Math.floor(cooldown % 60) + "秒"
          );
        } else {
          return cooldown / 60 + "分";
        }
      } else {
        return cooldown + "秒";
      }
    },
  },
}
</script>

<style scoped>
.item-icon {
  width: 60px;
  height: 60px;
}
</style>