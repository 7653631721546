<template>
  <div class="card card-content">
    <div class="card-header">
      浏览历史
      <div class="align-middle float-end">
        <button
          type="button"
          class="btn btn-outline-danger btn-xs"
          @click="resetHistory()"
        >
          清空
        </button>
      </div>
    </div>
    <ul class="list-group list-group-flush">
      <li
        class="list-group-item text-muted text-center"
        v-if="history.length == 0"
      >
        无历史记录
      </li>
      <a
        :href="'#/item/' + item.id"
        class="list-group-item list-group-item-action"
        v-else
        v-for="(item, index) in history"
        :key="item.id"
      >
        <div>
          <img
            :src="this.$root.getIconURL(item.icon)"
            class="rounded item-icon"
          />
          <span class="badge bg-secondary ms-1">
            {{ item.category }}
          </span>
          <div class="float-end">
            <button
              type="button"
              class="btn btn-outline-danger btn-xs"
              @click.prevent="deleteHistory(index)"
            >
              删除
            </button>
          </div>
        </div>
        <div class="ms-2 item-name">
          {{ item.name }}
          <span class="text-muted">
            <small> ILv.{{ item.ilv }} </small>
          </span>
        </div>
      </a>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ItemSearchHistory",
  props: ["history"],
  methods: {
    resetHistory() {
      this.$parent.resetHistory();
    },
    deleteHistory(index) {
      this.$parent.deleteHistory(index);
    },
  },
};
</script>

<style scoped>
.card > .list-group {
  overflow-y: auto;
}

.item-icon {
  width: 24px;
  height: 24px;
}

.item-name {
  padding-left: 24px;
}
</style>