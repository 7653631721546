import { createRouter, createWebHashHistory } from 'vue-router'
import Item from '@/views/Item.vue'

const routes = [
  {
    path: '/',
    redirect: 'item'
  },
  {
    path: '/item/:id(\\d+)?',
    name: 'Item',
    component: Item,
  },
  {
    path: '/leve/:id(\\d+)?',
    name: 'Leve',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Leve.vue')
  },
  {
    path: '/current',
    name: 'Current',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Current.vue')
  },
  {
    path: '/itemset',
    name: 'ItemSet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/ItemSet.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
