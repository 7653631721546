<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">
      雇员探险获得
    </span>
    <div class="row">
      <div class="col-6">
        <span class="badge item-info-badge me-1">雇员等级</span>
        <span class="align-middle">{{
            item.retainer.retainerLevel
        }}</span>
      </div>
      <div class="col-6" v-if="item.retainer.requiredItemLevel != 0">
        <span class="badge item-info-badge me-1">最低装等</span>
        <span class="align-middle">{{
            item.retainer.requiredItemLevel
        }}</span>
      </div>
      <div class="col-6" v-if="item.retainer.requiredGathering != 0">
        <span class="badge item-info-badge me-1">最低采集力</span>
        <span class="align-middle">{{
            item.retainer.requiredGathering
        }}</span>
      </div>
      <div class="col-6" v-else>
      </div>
      <div class="col-6">
        <span class="badge item-info-badge me-1">雇员经验</span>
        <span class="align-middle">{{ item.retainer.exp }}</span>
      </div>
      <div class="col-6">
        <span class="badge item-info-badge me-1">采集币</span>
        <span class="align-middle">{{ item.retainer.ventureCost }}</span>
      </div>
      <div class="col-6">
        <span class="badge rounded-pill bg-secondary item-badge">
          获取数量
        </span>
        <div>
          <template v-if="item.retainer.classJobCategory == 34">
            <span class="badge item-info-badge me-1">装等</span>
            <span class="badge bg-secondary me-1">{{
                item.retainer.requiredItemLevel
            }}</span>
          </template>
          <template v-if="item.retainer.classJobCategory < 34">
            <span class="badge item-info-badge me-1">采集力</span>
            <span class="badge bg-secondary me-1">{{
                item.retainer.requiredGathering
            }}</span>
          </template>
          <span class="align-middle">
            {{ item.retainer.quantity[0] }}
          </span>
        </div>
        <div v-for="(stat, index) in item.retainer.stat" :key="index">
          <span class="badge item-info-badge me-1" v-if="item.retainer.classJobCategory == 34">装等</span>
          <span class="badge item-info-badge me-1" v-if="item.retainer.classJobCategory < 34">鉴别力</span>
          <span class="badge bg-secondary me-1">{{ stat }}</span>
          <span class="align-middle">
            {{ item.retainer.quantity[index + 1] }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <span class="badge rounded-pill bg-secondary item-badge">
          探险时间
        </span>
        <div>
          <span class="badge item-info-badge me-1">Lv.{{
              this.getRetainerLevelTimeStr(
                item.retainer.retainerLevel,
                item.retainer.maxTime,
                0
              ).level
          }}</span>
          <span class="align-middle">{{
              this.getRetainerLevelTimeStr(
                item.retainer.retainerLevel,
                item.retainer.maxTime,
                0
              ).time
          }}
            分钟</span>
        </div>
        <div>
          <span class="badge item-info-badge me-1">Lv.{{
              this.getRetainerLevelTimeStr(
                item.retainer.retainerLevel,
                item.retainer.maxTime,
                1
              ).level
          }}</span>
          <span class="align-middle">{{
              this.getRetainerLevelTimeStr(
                item.retainer.retainerLevel,
                item.retainer.maxTime,
                1
              ).time
          }}
            分钟</span>
        </div>
        <div>
          <span class="badge item-info-badge me-1">Lv.{{
              this.getRetainerLevelTimeStr(
                item.retainer.retainerLevel,
                item.retainer.maxTime,
                2
              ).level
          }}</span>
          <span class="align-middle">{{
              this.getRetainerLevelTimeStr(
                item.retainer.retainerLevel,
                item.retainer.maxTime,
                2
              ).time
          }}
            分钟</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemInfoRetainer",
  props: ["item"],
  methods: {
    getRetainerLevelTimeStr(level, time, index) {
      return {
        level: parseInt(level) + 10 * index,
        time: parseInt(time) - 10 * index,
      };
    },
  }
}
</script>