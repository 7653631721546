<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">
      商店购买
    </span>
    <div class="row">
      <div class="col-12">
        <span class="badge item-info-badge me-1">购买价格</span>
        <span class="align-middle">{{ item.shopPrice }} 金币</span>
      </div>
    </div>
    <template v-for="(shop, index) in item.gilShop" :key="index">
      <template v-for="(npc, nindex) in shop.npc" :key="nindex">
        <div v-show="(index == 0 && nindex == 0) || showMoreGilshopNPC == true">
          <div>
            <span class="badge item-info-badge me-1">NPC</span>
            <span class="align-middle">{{ npc.name }}
              <span v-if="npc.title != ''">&lt;{{ npc.title }}&gt;</span>
            </span>
          </div>
          <div v-if="npc.location != null">
            <span class="badge item-info-badge me-1">地图</span>
            <span class="align-middle">
              {{ npc.location.map }}
              <span v-if="npc.location.x != null">(X: {{ npc.location.x }}, Y: {{ npc.location.y }})</span>
            </span>
          </div>
          <div v-if="shop.name != ''">
            <span class="badge item-info-badge me-1">分类</span>
            <span class="align-middle me-1" v-if="npc.topic_name != null">
              {{ npc.topic_name }}
            </span>
            <span class="align-middle">
              {{ shop.name }}
            </span>
          </div>
          <button type="button" class="btn btn-outline-primary btn-sm d-block w-100 mt-1 mb-1"
            v-if="index == 0 && nindex == 0 && (item.gilShop.length > 1 || shop.npc.length > 1)"
            @click="toggleMoreGilshopNPC($event)">
            <i class="bi align-middle" :class="gilshopNPCBtnIcon"></i> {{ gilshopNPCBtnText }}
          </button>
          <hr class="npc-divider"
            v-else-if="index != item.gilShop.length - 1 || (index == item.gilShop.length - 1 && nindex != shop.npc.length - 1)" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "ItemInfoGilShop",
  props: ["item"],
  data() {
    return {
      showMoreGilshopNPC: false,
      gilshopNPCBtnIcon: 'bi-caret-down-fill',
      gilshopNPCBtnText: '显示所有商店 NPC',
    }
  },
  methods: {
    toggleMoreGilshopNPC(event) {
      if (this.showMoreGilshopNPC) {
        this.showMoreGilshopNPC = false;
        this.gilshopNPCBtnIcon = 'bi-caret-down-fill';
        this.gilshopNPCBtnText = '显示所有商店 NPC';
      } else {
        this.showMoreGilshopNPC = true;
        this.gilshopNPCBtnIcon = 'bi-caret-up-fill';
        this.gilshopNPCBtnText = '折叠商店 NPC';
      }

      event.target.blur();
    }
  }
}
</script>

<style scoped>
.npc-divider {
  margin-top: 3px;
  margin-bottom: 3px;
}
</style>