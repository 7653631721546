<template>
  <div class="col-12" v-if="Object.keys(this.item.exchangeCost).length <= 3">
    <template v-for="(shop, index) in item.exchangeCost" :key="index">
      <span class="badge rounded-pill bg-secondary item-badge">
        可用于兑换
      </span>

      <ItemInfoExchange :shop="shop"></ItemInfoExchange>
    </template>
  </div>

  <div class="col-12" v-else>
    <div class="col-12">
      <span class="badge rounded-pill bg-secondary item-badge mb-1">
        可用于兑换 - 选择商店
      </span>
      <select class="form-select form-select-sm" v-model="currentShowShopID">
        <option value="0">请选择商店</option>
        <option v-for="(shop, index) in item.exchangeCost" :key="index" :value="shop.id">
          {{ shop.name != '' ? shop.name + ' - ' : '' }}
          {{ shop.npc != null && shop.npc[0].name != '' ? shop.npc[0].name :
              '???'
          }} @
          {{ shop.npc != null && shop.npc[0].location != null && shop.npc[0].location.map != '' ?
              shop.npc[0].location.map : '???'
          }}
        </option>
      </select>
    </div>
    <span class="badge rounded-pill bg-secondary item-badge" v-if="currentShowShopID > 0">
      可用于兑换
    </span>

    <ItemInfoExchange v-if="currentShowShopID > 0" :shop="item.exchangeCost[currentShowShopID]"></ItemInfoExchange>
  </div>
</template>

<script>
import ItemInfoExchange from './ItemInfoExchange.vue'

export default {
  name: "ItemInfoExchangeCost",
  props: ["item"],
  components: {
    ItemInfoExchange,
  },
  data() {
    return {
      currentShowShopID: 0,
    };
  },
}
</script>