<template>
  <div class="container-fluid main-container">
    <div class="row mt-1">
      <div class="col-xl main-col">
        <div class="btn-group mb-2" style="width: 100%" role="group">
          <input type="radio" class="btn-check" id="search" name="toggle-search" value="search" autocomplete="off"
            v-model="currentSelectedSearchType" />
          <label class="btn btn-outline-primary" for="search">搜索物品</label>

          <input type="radio" class="btn-check" id="notebook-list" name="toggle-search" value="notebook"
            autocomplete="off" v-model="currentSelectedSearchType" />
          <label class="btn btn-outline-primary" for="notebook-list">制作笔记</label>
        </div>
        <component :is="currentSearchComponent"></component>
      </div>
      <div class="col-xl-2-5 main-col">
        <ItemSearchResult :loading="itemSearchLoading" :hasError="itemSearchHasError" :errorMsg="itemSearchErrorMsg"
          :itemsList="itemSearchResult" :currentPage="itemSearchCurrentPage" :totalPages="itemSearchTotalPages" />
      </div>
      <div class="col-xl-4 main-col">
        <ItemSearchItemDetail :item="currentNavItem" :loading="itemLoading" :hasError="itemHasError"
          :errorMsg="itemErrorMsg" />
      </div>
      <div class="col-xl main-col">
        <ItemSearchHistory :history="itemSearchHistoryData" />
      </div>
    </div>
  </div>
</template>

<script>
import ItemSearchBar from "@/components/ItemSearchBar.vue";
import ItemSearchNotebook from "@/components/ItemSearchNotebook.vue";
import ItemSearchResult from "@/components/ItemSearchResult.vue";
import ItemSearchItemDetail from "@/components/ItemSearchItemDetail.vue";
import ItemSearchHistory from "@/components/ItemSearchHistory.vue";

export default {
  name: "Item",
  components: {
    ItemSearchBar,
    ItemSearchNotebook,
    ItemSearchResult,
    ItemSearchItemDetail,
    ItemSearchHistory,
  },
  data() {
    return {
      itemSearchLoading: false,
      itemSearchHasError: false,
      itemSearchErrorMsg: "",
      itemSearchResult: new Array(),

      itemSearchCurrentPage: 0,
      itemSearchTotalPages: 0,
      lastSearchData: null,
      lastSearchFirstID: 0,
      lastSearchLastID: 0,

      currentNavItem: null,
      currentSelectedSearchType: "search",

      itemLoading: false,
      itemHasError: false,
      itemErrorMsg: "",

      canUseLocalStorage: false,
      itemSearchHistoryData: new Array(),
    };
  },
  computed: {
    currentSearchComponent() {
      switch (this.currentSelectedSearchType) {
        case "search":
        default:
          return ItemSearchBar;
        case "notebook":
          return ItemSearchNotebook;
      }
    },
  },
  methods: {
    itemSearchSubmit(formData, isChangePage = false) {
      if (!isChangePage) {
        this.itemSearchCurrentPage = 0;
        this.itemSearchTotalPages = 0;
        this.lastSearchData = Object.assign({}, formData);
      }

      this.itemSearchTotalPage = 0;
      this.itemSearchHasError = false;
      this.itemSearchErrorMsg = "";
      this.itemSearchResult = new Array();
      this.itemSearchLoading = true;

      var self = this;
      this.axios
        .post(this.apiBaseURL + "item.php?method=searchItem", formData)
        .then(function (response) {
          if (response.data.success) {
            self.itemSearchResult = response.data.data;
            self.itemSearchLoading = false;

            if (response.data.data.length > 0) {
              if (!isChangePage) {
                self.itemSearchCurrentPage = 1;
                self.itemSearchTotalPages = response.data.total_pages;
              }

              self.lastSearchFirstID = response.data.data[0].id;
              self.lastSearchLastID =
                response.data.data[response.data.data.length - 1].id;
            }
          } else {
            self.itemSearchHasError = true;
            self.itemSearchErrorMsg = "搜索失败: " + response.data.error;
            self.itemSearchLoading = false;
          }
        })
        .catch(function (error) {
          self.itemSearchHasError = true;
          self.itemSearchErrorMsg = "搜索失败: " + error;
          self.itemSearchLoading = false;
        });
    },
    itemSearchChangePage(direction) {
      if (
        this.itemSearchCurrentPage + direction > this.itemSearchTotalPages ||
        this.itemSearchCurrentPage + direction <= 0
      ) {
        return;
      }

      this.lastSearchData.pageDirection = direction;
      this.lastSearchData.lastID =
        direction == -1 ? this.lastSearchFirstID : this.lastSearchLastID;

      this.itemSearchCurrentPage += direction;

      this.itemSearchSubmit(this.lastSearchData, true);
    },
    openNotebook(id, craftType) {
      this.itemSearchTotalPage = 0;
      this.itemSearchHasError = false;
      this.itemSearchErrorMsg = "";
      this.itemSearchResult = new Array();
      this.itemSearchLoading = true;

      var self = this;
      this.axios
        .post(this.apiBaseURL + "notebook.php?method=getNotebookRecipeList", {
          id: id,
          craftType: craftType,
        })
        .then(function (response) {
          if (response.data.success) {
            self.itemSearchResult = response.data.data;
            self.itemSearchLoading = false;

            if (response.data.data.length > 0) {
              self.itemSearchCurrentPage = 1;
              self.itemSearchTotalPages = 1;

              self.lastSearchFirstID = null;
              self.lastSearchLastID = null;
            }
          } else {
            self.itemSearchHasError = true;
            self.itemSearchErrorMsg = "搜索失败: " + response.data.error;
            self.itemSearchLoading = false;
          }
        })
        .catch(function (error) {
          self.itemSearchHasError = true;
          self.itemSearchErrorMsg = "搜索失败: " + error;
          self.itemSearchLoading = false;
        });
    },
    createHistoryEntry(item) {
      var index = this.itemSearchHistoryData.findIndex(
        (ele) => ele.id == item.id
      );

      if (index === -1) {
        var count = this.itemSearchHistoryData.unshift({
          id: item.id,
          icon: item.icon,
          name: item.name,
          category: item.itemUICategory,
          ilv: item.itemLevel,
        });
      } else {
        this.itemSearchHistoryData.splice(index, 1);

        this.itemSearchHistoryData.unshift({
          id: item.id,
          icon: item.icon,
          name: item.name,
          category: item.itemUICategory,
          ilv: item.itemLevel,
        });
      }

      if (count > 100) {
        this.itemSearchHistoryData.pop();
      }

      if (this.canUseLocalStorage) {
        var storage = window.localStorage;

        storage.setItem(
          "itemHistory",
          JSON.stringify(this.itemSearchHistoryData)
        );
      }
    },
    resetHistory() {
      this.itemSearchHistoryData = new Array();

      if (this.canUseLocalStorage) {
        var storage = window.localStorage;

        storage.removeItem("itemHistory");
      }
    },
    deleteHistory(index) {
      this.itemSearchHistoryData.splice(index, 1);

      if (this.canUseLocalStorage) {
        var storage = window.localStorage;

        storage.setItem(
          "itemHistory",
          JSON.stringify(this.itemSearchHistoryData)
        );
      }
    },
  },
  beforeRouteUpdate(to) {
    var currentNavItemID = to.params.id > 0 ? to.params.id : 0;
    if (currentNavItemID > 0) {
      if (
        this.currentNavItem == null ||
        (this.currentNavItem != null &&
          currentNavItemID != this.currentNavItem.id)
      ) {
        this.currentNavItem = null;

        this.itemLoading = true;
        this.itemHasError = false;
        this.itemErrorMsg = "";

        var self = this;
        this.axios
          .post(this.apiBaseURL + "item.php?method=getItem", {
            id: currentNavItemID,
          })
          .then(function (response) {
            if (response.data.success) {
              self.itemLoading = false;

              self.currentNavItem = response.data.data;

              self.createHistoryEntry(response.data.data);

              //document.querySelector("#item-detail-card").scrollTop = 0;
            } else {
              self.itemLoading = false;
              self.itemHasError = true;
              self.itemErrorMsg = "物品加载失败: " + response.data.error;
            }
          })
          .catch(function (error) {
            self.itemLoading = false;
            self.itemHasError = true;
            self.itemErrorMsg = "物品加载失败: " + error;
          });
      }
    }
  },
  mounted() {
    if (this.$root.storageAvailable("localStorage")) {
      this.canUseLocalStorage = true;

      var storage = window.localStorage;

      if (storage.getItem("itemHistory") != null) {
        this.itemSearchHistoryData = JSON.parse(storage.getItem("itemHistory"));
      }
    } else {
      console.log("localStorage not available.");
    }
  },
  activated() {
    var currentNavItemID =
      this.$route.params.id > 0 ? this.$route.params.id : 0;

    var getItem = false;
    if (this.currentNavItem != null) {
      if (currentNavItemID != this.currentNavItem.id && currentNavItemID > 0) {
        getItem = true;
      }
    } else {
      if (currentNavItemID > 0) {
        getItem = true;
      }
    }

    if (getItem) {
      this.currentNavItem = null;

      this.itemLoading = true;
      this.itemHasError = false;
      this.itemErrorMsg = "";

      var self = this;
      this.axios
        .post(this.apiBaseURL + "item.php?method=getItem", {
          id: currentNavItemID,
        })
        .then(function (response) {
          if (response.data.success) {
            self.itemLoading = false;

            self.currentNavItem = response.data.data;

            self.createHistoryEntry(response.data.data);

            //document.querySelector("#item-detail-card").scrollTop = 0;
          } else {
            self.itemLoading = false;
            self.itemHasError = true;
            self.itemErrorMsg = "物品加载失败: " + response.data.error;
          }
        })
        .catch(function (error) {
          self.itemLoading = false;
          self.itemHasError = true;
          self.itemErrorMsg = "物品加载失败: " + error;
        });
    }
  },
};
</script>

<style scope>
.card-content {
  height: 100%;
}

.main-col {
  min-height: 550px;
  max-height: calc(100vh - 140px);
}

.col-xl-2-5 {
  flex: 0 0 auto;
  width: 23%;
}
</style>
