<template>
  <div class="col-12">
    <div class="col-12" v-if="item.gatheringPoint.length > 1">
      <span class="badge rounded-pill bg-secondary item-badge mb-1">
        选择采集点
      </span>
      <select class="form-select form-select-sm" v-model="currentShowPoint">
        <option v-for="(point, index) in item.gatheringPoint" :key="index" :value="index">
          Lv.{{ point.level }} {{ point.gatheringType }} {{ point.pointType }} @ {{ point.location.map }}
        </option>
      </select>
    </div>

    <template v-for="(point, index) in item.gatheringPoint" :key="index">
      <div class="col-12" v-show="currentShowPoint == index">
        <span class="badge rounded-pill bg-secondary item-badge">
          采集获得
        </span>
        <div class="row">
          <div class="col-6">
            <span class="badge item-info-badge me-1">采集职业</span>
            <span class="align-middle">{{ point.classJob }}</span>
            <span class="align-middle" v-if="point.gatheringType != null"> / {{ point.gatheringType }}</span>
          </div>
          <div class="col-6">
            <span class="badge item-info-badge me-1">采集点类型</span>
            <span class="align-middle">{{ point.pointType }}</span>
          </div>
          <div class="col-6">
            <span class="badge item-info-badge me-1">等级</span>
            <span class="align-middle">Lv.{{ point.level }}</span>
          </div>
          <div class="col-12">
            <span class="badge item-info-badge me-1">地点</span>
            <span class="align-middle">{{ point.location.map }} (X:
              {{ point.location.x }}, Y:
              {{ point.location.y }})</span>
          </div>
        </div>

        <span class="badge rounded-pill bg-secondary item-badge" v-if="
          point.limitTime != null ||
          point.popUpTime != null
        ">
          采集点刷新时间
        </span>
        <div class="row" v-if="point.limitTime != null">
          <template v-for="(time, tindex) in point.limitTime" :key="tindex">
            <div class="col-6">
              <span class="badge item-info-badge me-1">刷新时间</span>
              <span class="badge bg-secondary me-1">ET</span>
              <span class="align-middle">{{
                  time.startTime.padStart(4, "0")
              }}</span>
            </div>
            <div class="col-6">
              <span class="badge item-info-badge me-1">结束时间</span>
              <span class="badge bg-secondary me-1">ET</span>
              <span class="align-middle">{{
                  time.endTime.padStart(4, "0")
              }}</span>
            </div>
          </template>
        </div>
        <div class="row" v-if="point.popUpTime != null">
          <div class="col-6" v-for="(time, tindex) in point.popUpTime" :key="tindex">
            <span class="badge bg-secondary me-1">ET</span>
            <span class="align-middle">{{
                time.start.padStart(4, "0")
            }}</span>
          </div>
        </div>

        <span class="badge rounded-pill item-notice-badge item-badge" v-if="point.requiredFolkloreBook != null">
          需要解锁 <a :href="'#/item/' + point.requiredFolkloreBook.id" class="text-reset">{{
              point.requiredFolkloreBook.name
          }}</a>
        </span>

        <span class="badge rounded-pill bg-secondary item-badge">
          采集点物品
        </span>
        <div class="" v-for="(gitem, iindex) in point.items" :key="gitem.id">
          <span class="badge bg-secondary me-1">{{ iindex + 1 }}</span>
          <span class="badge bg-secondary me-1">Lv.{{ gitem.level }}{{ getRecipeStar(gitem.stars) }}</span>
          <span class="align-middle">
            <a :href="'#/item/' + gitem.id">
              <img :src="this.$root.getIconURL(gitem.icon)" class="rounded" width="24" height="24" />
            </a>
            {{ gitem.name }}
            <span class="badge bg-primary" v-if="gitem.addedGameVer != null">{{ gitem.addedGameVer }}</span>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ItemInfoGatheringPoint",
  props: ["item"],
  data() {
    return {
      currentShowPoint: 0,
    };
  },
  methods: {
    getRecipeStar(starnum) {
      var string = "";

      for (let i = 0; i < starnum; i++) {
        string += "★";
      }

      return string;
    },
  }
}
</script>