<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">可用于购买</span>

    <div>
      <span class="badge item-info-badge me-1">NPC</span>
      <span class="align-middle">
        {{ item.GCScripCost.npc.name }}
        <span v-if="item.GCScripCost.npc.title != ''">
          &lt;{{ item.GCScripCost.npc.title }}&gt;
        </span>
      </span>
    </div>
    <div v-if="item.GCScripCost.npc.location != null">
      <span class="badge item-info-badge me-1">地图</span>
      <span class="align-middle">
        {{ item.GCScripCost.npc.location.map }}
        <span v-if="item.GCScripCost.npc.location.x != null">(X: {{ item.GCScripCost.npc.location.x }}, Y: {{
            item.GCScripCost.npc.location.y
        }})</span>
      </span>
    </div>

    <div class="btn-group mt-1 mb-2 w-100 btn-group-sm">
      <input type="radio" class="btn-check" name="gcshop-tier" id="gcshop-tier1" autocomplete="off"
        v-model="currentTier" :value="1">
      <label class="btn btn-outline-primary" for="gcshop-tier1">{{ getTierName(1) }}</label>

      <input type="radio" class="btn-check" name="gcshop-tier" id="gcshop-tier2" autocomplete="off"
        v-model="currentTier" :value="2">
      <label class="btn btn-outline-primary" for="gcshop-tier2">{{ getTierName(2) }}</label>

      <input type="radio" class="btn-check" name="gcshop-tier" id="gcshop-tier3" autocomplete="off"
        v-model="currentTier" :value="3">
      <label class="btn btn-outline-primary" for="gcshop-tier3">{{ getTierName(3) }}</label>
    </div>

    <div class="btn-group mb-1 w-100 btn-group-sm">
      <input type="radio" class="btn-check" name="gcshop-cat" id="gcshop-cat1" autocomplete="off"
        v-model="currentCategory" :value="2">
      <label class="btn btn-outline-secondary" for="gcshop-cat1">武器</label>

      <input type="radio" class="btn-check" name="gcshop-cat" id="gcshop-cat2" autocomplete="off"
        v-model="currentCategory" :value="3">
      <label class="btn btn-outline-secondary" for="gcshop-cat2">防具</label>

      <input type="radio" class="btn-check" name="gcshop-cat" id="gcshop-cat3" autocomplete="off"
        v-model="currentCategory" :value="1">
      <label class="btn btn-outline-secondary" for="gcshop-cat3">军用品</label>

      <input type="radio" class="btn-check" name="gcshop-cat" id="gcshop-cat4" autocomplete="off"
        v-model="currentCategory" :value="4">
      <label class="btn btn-outline-secondary" for="gcshop-cat4">素材</label>
    </div>

    <div v-if="item.GCScripCost.items[currentTier][currentCategory] == null">
      <p class="text-center text-muted">没有可以交换的物品</p>
    </div>

    <div v-else class="row">
      <div class="col-6">
        <span class="badge rounded-pill bg-secondary item-badge">
          物品
        </span>
      </div>
      <div class="col-3">
        <span class="badge rounded-pill bg-secondary item-badge">
          消耗
        </span>
      </div>
      <div class="col-3">
        <span class="badge rounded-pill bg-secondary item-badge">
          需要军衔
        </span>
      </div>
      <template v-for="(gitem, index) in item.GCScripCost.items[currentTier][currentCategory]" :key="index">
        <div class="col-6">
          <a :href="'#/item/' + gitem.id">
            <img :src="this.$root.getIconURL(gitem.icon)" class="rounded" width="24" height="24" />
          </a>
          <span class="ms-1 align-middle">{{ gitem.name }}</span>
        </div>
        <div class="col-3 text-center">
          <a :href="'#/item/' + item.id">
            <img :src="this.$root.getIconURL(item.icon)" class="rounded" width="24" height="24" />
          </a>
          <span class="ms-1 align-middle">{{ gitem.cost }}</span>
        </div>
        <div class="col-3 text-center">
          <span class="align-middle">{{ gitem.requiredRank }}</span>
        </div>
        <div class="col-12" v-if="index != item.GCScripCost.items[currentTier][currentCategory].length - 1">
          <hr class="npc-divider" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemInfoGCShopCost",
  props: ["item"],
  data() {
    return {
      currentTier: 1,
      currentCategory: 2,
    };
  },
  methods: {
    getTierName(tier) {
      switch (this.item.id) {
        case 20:
          switch (tier) {
            case 1:
              return '漩兵长以下';
            case 2:
              return '漩士长以下';
            case 3:
              return '正漩尉以下';
          }
          break;
        case 21:
          switch (tier) {
            case 1:
              return '牙兵长以下';
            case 2:
              return '牙士长以下';
            case 3:
              return '正牙尉以下';
          }
          break;
        case 22:
          switch (tier) {
            case 1:
              return '耀兵长以下';
            case 2:
              return '耀士长以下';
            case 3:
              return '正耀尉以下';
          }
          break;
      }
    }
  },
}
</script>

<style scoped>
.npc-divider {
  margin-top: 3px;
  margin-bottom: 3px;
}
</style>