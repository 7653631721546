<template>
  <nav class="navbar fixed-top navbar-light bg-light navbar-expand-lg">
    <div class="container-fluid">
      <span class="navbar-brand">FFXIV 数据库</span>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav" v-if="isInit">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/item" custom v-slot="{ navigate, href, isActive }">
              <a :href="href" @click="navigate" :class="[
                { active: isActive },
                { active: $route.name == 'Item' },
                'nav-link',
              ]">物品/配方查询
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/itemset" custom v-slot="{ navigate, href, isActive }">
              <a :href="href" @click="navigate" :class="[{ active: isActive }, 'nav-link']">套装计算器
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/leve" custom v-slot="{ navigate, href, isActive }">
              <a :href="href" @click="navigate" :class="[
                { active: isActive },
                { active: $route.name == 'Leve' },
                'nav-link',
              ]">理符查询
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/current" custom v-slot="{ navigate, href, isActive }">
              <a :href="href" @click="navigate" :class="[{ active: isActive }, 'nav-link']">风脉泉
              </a>
            </router-link>
          </li>
        </ul>
        <!--<span class="navbar-text">
          <span class="badge bg-secondary me-1">
            本地 {{ currentTime }}
          </span>
          <span class="badge bg-secondary">
            ET {{ currentETTime }}
          </span>
        </span>-->

      </div>
    </div>
  </nav>
  <router-view v-slot="{ Component }" v-if="isInit">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <div class="container-fluid mt-2 d-xl-block d-sm-none mb-2">
    <p class="text-center text-muted" v-if="!isInit && !isMaintenance && !isError">
      <span class="spinner-border spinner-border-sm text-primary"></span>
      加载中...
    </p>
    <p class="text-center text-danger" v-if="isMaintenance">
      系统正在维护，请稍后再使用。
    </p>
    <p class="text-center text-danger" v-if="isError">
      系统错误: {{ errorMsg }}，请稍后重试。
    </p>
  </div>
  <div class="container-fluid mt-2 d-xl-block d-sm-none mb-2" style="font-size: small; width: 85%">
    <span class="text-muted" v-if="isInit">数据版本: 国服 {{ gameVer }} / 国际服 {{ intlGameVer }}</span>
    <p class="text-muted mb-0">
      FINAL FANTASY is a registered trademark of Square Enix Holdings Co., Ltd. © SQUARE ENIX CO., LTD. All Rights
      Reserved.
    </p>
  </div>
</template>

<script>
//import { getEorzeaTime } from './eorzea-time';

export default {
  data() {
    return {
      isInit: false,
      isError: false,
      errorMsg: '',
      isMaintenance: false,
      gameVer: '',
      intlGameVer: '',

      currentETTime: '',
      currentTime: '',
    };
  },
  created() {
    /*setInterval(() => {
      var time = new Date();
      var ettime = getEorzeaTime(time);
      this.currentETTime = ettime.h + ':' + ettime.m;
      this.currentTime = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0');
    }, 1000);*/

    var self = this;
    this.axios
      .get(this.apiBaseURL + "web.php?method=getGameVer")
      .then(function (response) {
        if (response.data.success) {
          self.isInit = true;
          self.gameVer = response.data.game_ver;
          self.intlGameVer = response.data.game_ver_intl;
        } else {
          if (response.data.maintenance != null) {
            self.isMaintenance = true;
          } else {
            self.isError = true;
            self.errorMsg = response.data.error;
          }
        }
      })
      .catch(function (error) {
        self.isError = true;
        self.errorMsg = error;
      });
  },
  methods: {
    getIconURL(icon) {
      var baseURL = this.iconBaseURL + "/assets/icon/";
      var iconFull = icon.toString().padStart(6, "0");

      var url =
        baseURL +
        iconFull.substring(0, 3).padEnd(6, "0") +
        "/" +
        iconFull +
        "_hr1.png";

      return url;
    },
    storageAvailable(type) {
      var storage;
      try {
        storage = window[type];
        var x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return (
          e instanceof DOMException &&
          // everything except Firefox
          (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === "QuotaExceededError" ||
            // Firefox
            e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage &&
          storage.length !== 0
        );
      }
    },
  },
};
</script>

<style>
#app {
  margin-top: 70px;
}

.main-container {
  width: 85%;
}

/* 滚动槽 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}
</style>
