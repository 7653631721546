<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">
      基本信息
    </span>
    <div class="row">
      <div class="col-12" v-if="item.equipLevel > 0">
        <span class="badge item-info-badge me-1">职业</span>
        <span class="align-middle">{{ item.classJobCategory }}</span>
      </div>
      <div class="col-6" v-if="item.equipLevel > 0">
        <span class="badge item-info-badge me-1">装备等级</span>
        <span class="align-middle">Lv.{{ item.equipLevel }} 以上</span>
      </div>
      <div class="col-6">
        <span class="badge item-info-badge me-1">品级</span>
        <span class="align-middle">{{ item.itemLevel }}</span>
      </div>
      <div class="col-6" v-if="item.equipLevel > 0">
        <span class="badge item-info-badge me-1">武具投影</span>
        <span class="align-middle" v-if="item.isGlamourous == 1">
          <i class="bi bi-check-lg"></i>
        </span>
        <span class="align-middle" v-else>
          <i class="bi bi-x-lg"></i>
        </span>
      </div>
      <div class="col-6" v-if="item.equipLevel > 0">
        <span class="badge item-info-badge me-1">染色</span>
        <span class="align-middle" v-if="item.isDyeable == 1">
          <i class="bi bi-check-lg"></i>
        </span>
        <span class="align-middle" v-else>
          <i class="bi bi-x-lg"></i>
        </span>
      </div>
      <div class="col-6" v-if="item.equipLevel == null && item.isUnique == 0">
        <span class="badge item-info-badge me-1">堆叠上限</span>
        <span class="align-middle">{{ item.stackSize }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <template v-if="item.sellPrice > 0">
          <span class="badge item-info-badge me-1">出售价格</span>
          <span class="align-middle">{{ item.sellPrice }} 金币</span>
        </template>
        <span class="badge text-bg-secondary me-1" v-else>不可出售</span>
      </div>
      <div class="col-6" v-if="item.canSellOnMarket == 0">
        <span class="badge text-bg-secondary me-1">不可在市场出售</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemInfoBasic",
  props: ["item"],
}
</script>