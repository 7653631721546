<template>
  <form @submit.prevent="onSubmit">
    <div class="card mb-2">
      <div class="card-body">
        <div class="mb-2">
          <input class="form-control" type="text" v-model="formData.name" placeholder="请输入物品名..." />
        </div>
        <div class="mb-2">
          <div class="input-group">
            <select class="form-select" v-model="currentSelectedMainCategory">
              <option value="0">物品类型</option>
              <option v-for="(category, index) in mainCategory" :key="index" :value="index">
                {{ category }}
              </option>
            </select>
            <select class="form-select" v-model="formData.category">
              <option value="0">-</option>
              <option v-for="category in subCategory" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-2">
          <select class="form-select" v-model="formData.classJob">
            <option value="0">选择职业</option>
            <option v-for="job in classJob" :key="job.id" :value="job.id">
              {{ job.name }}
            </option>
          </select>
        </div>
        <div class="mb-2" v-if="formData.classJob == 16 || formData.classJob == 17">
          <button type="button" class="btn btn-outline-primary d-block" @click="changeCollectableOption">
            仅搜索收藏品: {{ collectableOnlyText }}
          </button>
        </div>
        <div class="mb-2">
          <div class="input-group">
            <span class="input-group-text" v-if="formData.collectableOnly">缴纳等级</span>
            <span class="input-group-text" v-else>装备等级</span>
            <input type="number" class="form-control" v-model="formData.minLv" placeholder="最小" min="0" max="90" />
            <input type="number" class="form-control" v-model="formData.maxLv" placeholder="最大" min="0" max="90" />
          </div>
        </div>
        <div class="mb-2" v-if="formData.collectableOnly == false">
          <div class="input-group">
            <span class="input-group-text">物品品级</span>
            <input type="number" class="form-control" v-model="formData.minItemLv" placeholder="最小" min="0" max="999" />
            <input type="number" class="form-control" v-model="formData.maxItemLv" placeholder="最大" min="0" max="999" />
          </div>
        </div>
        <div class="mb-2">
          <select class="form-select" v-model="formData.gameVer">
            <option value="0">物品加入游戏版本</option>
            <option v-for="(ver, index) in gamaVerList" :key="index" :value="ver">
              {{ ver }}
              <template v-if="ver == this.$root.gameVer"> (国服当前版本)</template>
              <template v-if="ver == this.$root.intlGameVer"> (国际服当前版本)</template>
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-6 pe-1">
            <button class="btn btn-primary d-block" type="submit">搜索</button>
          </div>
          <div class="col-6 ps-1">
            <button class="btn btn-danger d-block" type="button" @click="resetForm">
              重置
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "ItemSearchBar",
  data() {
    return {
      formData: {
        name: null,
        mainCategory: 0,
        category: 0,
        minLv: null,
        maxLv: null,
        minItemLv: null,
        maxItemLv: null,
        gameVer: 0,
        classJob: 0,
        collectableOnly: false,
      },

      mainCategory: {
        1: "武器",
        2: "工具",
        3: "防具",
        4: "饰品",
        5: "食药",
        6: "素材",
        7: "其他",
      },
      subCategoryData: {},
      classJob: {},

      currentSelectedMainCategory: 0,
      subCategory: {},

      gamaVerList: {},
    };
  },
  computed: {
    collectableOnlyText() {
      return this.formData.collectableOnly ? "是" : "否";
    },
  },
  created() {
    var self = this;
    this.axios
      .get(this.apiBaseURL + "item.php?method=init")
      .then(function (response) {
        if (response.data.success) {
          self.subCategoryData = response.data.data.item_category;
          self.classJob = response.data.data.classjob;
          self.gamaVerList = response.data.data.game_ver_list;
        } else {
          alert("发生错误：" + response.data.error + "，请稍后重试。");
        }
      })
      .catch(function (error) {
        alert("服务器错误，请稍后重试。(" + error + ")");
      });
  },
  watch: {
    currentSelectedMainCategory(newCat, oldCat) {
      if (newCat != oldCat) {
        this.subCategory = this.subCategoryData[newCat];
        this.formData.category = 0;
      }

      this.formData.mainCategory = this.currentSelectedMainCategory;
    },
  },
  methods: {
    resetForm() {
      this.formData = {
        name: null,
        mainCategory: 0,
        category: 0,
        minLv: null,
        maxLv: null,
        minItemLv: null,
        maxItemLv: null,
        gameVer: 0,
        classJob: 0,
        collectableOnly: false,
      };

      this.currentSelectedMainCategory = 0;
    },
    onSubmit() {
      this.$parent.itemSearchSubmit(this.formData);
    },
    changeCollectableOption() {
      if (this.formData.collectableOnly) {
        this.formData.collectableOnly = false;
      } else {
        this.formData.collectableOnly = true;
        this.formData.minItemLv = null;
        this.formData.maxItemLv = null;
      }
    },
  },
};
</script>

<style scoped>
button.d-block {
  width: 100%;
}
</style>