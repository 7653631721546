<template>
  <template v-if="item.recipe != null">
    <div class="col-12" v-if="item.recipe.length > 1">
      <span class="badge rounded-pill bg-secondary item-badge mb-1">
        选择制作职业
      </span>
      <select class="form-select form-select-sm" v-model="currentShowRecipe">
        <option v-for="(recipe, index) in item.recipe" :key="index" :value="index">{{ getCraftTypeJob(recipe.craftType)
        }}
        </option>
      </select>
    </div>
    <template v-for="(recipe, index) in item.recipe" :key="index">
      <div class="col-12" v-show="currentShowRecipe == index">
        <span class="badge rounded-pill bg-secondary item-badge">
          制作信息
        </span>
        <div class="row">
          <div class="col-6">
            <span class="badge item-info-badge me-1">职业</span>
            <span class="align-middle">{{ getCraftTypeJob(recipe.craftType) }}</span>
          </div>
          <div class="col-6">
            <span class="badge item-info-badge me-1">配方等级</span>
            <span class="align-middle">Lv.{{ recipe.recipeLevel.classJobLevel }}</span>
            <span class="align-middle ms-1">{{ getRecipeStar(recipe.recipeLevel.stars) }}</span>
          </div>
          <div class="col-12">
            <span class="badge item-info-badge me-1">获得数量</span>
            <span class="align-middle">{{ recipe.amount }}</span>
          </div>
          <div class="col-6">
            <span class="badge item-info-badge me-1">难度</span>
            <span class="align-middle">{{ recipe.difficulty }}</span>
          </div>
          <div class="col-6">
            <span class="badge item-info-badge me-1">品质</span>
            <span class="align-middle">{{ recipe.quality }}</span>
          </div>
          <div class="col-6">
            <span class="badge item-info-badge me-1">耐久</span>
            <span class="align-middle">{{ recipe.durability }}</span>
          </div>
          <div class="col-6">
            (初期品质上限 {{ recipe.materialQualityFactor }}%)
          </div>
          <div class="col-6">
            <span class="badge item-info-badge me-1">简易制作</span>
            <span class="align-middle" v-if="recipe.canQuickSynth == 1">
              <i class="bi bi-check-lg"></i>
            </span>
            <span class="align-middle" v-else>
              <i class="bi bi-x-lg"></i>
            </span>
          </div>
          <div class="col-6" v-if="item.isCollectable != 0">
            <span class="badge item-info-badge me-1" v-if="item.alwaysCollectable != null">仅可制作收藏品</span>
            <span v-else>
              <span class="badge item-info-badge me-1">收藏品制作</span>
              <span class="align-middle">
                <i class="bi bi-check-lg"></i>
              </span>
            </span>
          </div>
          <div class="col-6" v-else>
            <span class="badge item-info-badge me-1">HQ 制作</span>
            <span class="align-middle" v-if="recipe.canHQ == 1">
              <i class="bi bi-check-lg"></i>
            </span>
            <span class="align-middle" v-else>
              <i class="bi bi-x-lg"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="col-12" v-show="currentShowRecipe == index">
        <span class="badge rounded-pill text-bg-warning item-badge" v-if="recipe.isExpert == 1">
          高难度配方
        </span>
        <span class="badge rounded-pill item-notice-badge item-badge" v-if="recipe.isSpecializationRequired == 1">
          专家配方 (需要专家水晶才可制作)
        </span>
        <span class="badge rounded-pill item-notice-badge item-badge" v-if="recipe.secretRecipeBook != null">
          需要解锁秘籍 <a :href="'#/item/' + recipe.secretRecipeBook.itemID" class="text-reset">{{
              recipe.secretRecipeBook.name
          }}</a>
        </span>
        <span class="badge rounded-pill item-notice-badge item-badge" v-if="recipe.requiredCraftsmanship != 0">
          作业精度需达到 {{ recipe.requiredCraftsmanship }} 以上
        </span>
        <span class="badge rounded-pill item-notice-badge item-badge" v-if="recipe.requiredControl != 0">
          加工精度需达到 {{ recipe.requiredControl }} 以上
        </span>
        <span class="badge rounded-pill item-notice-badge item-badge" v-if="recipe.quickSynthCraftsmanship != 0">
          简易制作作业精度需达到 {{ recipe.quickSynthCraftsmanship }} 以上
        </span>
        <span class="badge rounded-pill item-notice-badge item-badge" v-if="recipe.quickSynthControl != 0">
          简易制作加工精度需达到 {{ recipe.quickSynthControl }} 以上
        </span>
      </div>

      <div class="col-12" v-if="recipe.ingredients != null" v-show="currentShowRecipe == index">
        <span class="badge rounded-pill bg-secondary item-badge">
          制作原料
        </span>

        <ItemIngredient v-for="ingredient in recipe.ingredients" :key="ingredient.id" :item="ingredient"
          :secondary="false" />
      </div>
    </template>
  </template>

  <div class="col-12" v-if="item.canCraft != null">
    <span class="badge rounded-pill bg-secondary item-badge">
      可用于制作
    </span>
    <div v-for="item in item.canCraft" :key="item.item.id">
      <span class="badge item-info-badge me-1">{{ getCraftTypeJob(item.craftType) }}</span>
      <span class="badge bg-secondary me-1">Lv.{{ item.classJobLevel }}{{ getRecipeStar(item.stars) }}</span>
      <span class="align-middle">
        <a :href="'#/item/' + item.item.id">
          <img :src="this.$root.getIconURL(item.item.icon)" class="rounded" width="24" height="24" />
        </a>
        {{ item.item.name }} x{{ item.amount }}
      </span>
      <span class="badge bg-primary ms-1" v-if="item.item.addedGameVer != null">{{ item.item.addedGameVer }}</span>
    </div>
  </div>
</template>

<script>
import ItemIngredient from "./ItemIngredient.vue";

export default {
  name: "ItemInfoRecipe",
  props: ["item"],
  components: {
    ItemIngredient
  },
  data() {
    return {
      currentShowRecipe: 0,
    };
  },
  methods: {
    getCraftTypeJob(type) {
      type = parseInt(type);

      switch (type) {
        case 0:
          return "刻木匠";
        case 1:
          return "锻铁匠";
        case 2:
          return "铸甲匠";
        case 3:
          return "雕金匠";
        case 4:
          return "制革匠";
        case 5:
          return "裁衣匠";
        case 6:
          return "炼金术士";
        case 7:
          return "烹调师";
      }
    },
    getRecipeStar(starnum) {
      var string = "";

      for (let i = 0; i < starnum; i++) {
        string += "★";
      }

      return string;
    },
  }
}
</script>