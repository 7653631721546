<template>
  <div v-if="shop.name != ''">
    <span class="badge item-info-badge me-1">分类</span>
    <span class="align-middle">
      {{ shop.name }}
    </span>
    <hr class="npc-divider" v-if="shop.npc != null" />
  </div>

  <template v-if="shop.npc != null">
    <template v-for="(npc, index) in shop.npc" :key="index">
      <div>
        <span class="badge item-info-badge me-1">NPC</span>
        <span class="align-middle">
          {{ npc.name }}
          <span v-if="npc.title != ''">
            &lt;{{ npc.title }}&gt;
          </span>
        </span>
      </div>
      <div v-if="npc.location != null">
        <span class="badge item-info-badge me-1">地图</span>
        <span class="align-middle">
          {{ npc.location.map }}
          <span v-if="npc.location.x != null">(X: {{ npc.location.x }}, Y: {{ npc.location.y }})</span>
        </span>
      </div>
      <hr class="npc-divider" v-if="index != shop.npc.length - 1" />
    </template>
  </template>

  <div class="row">
    <div class="col-6">
      <span class="badge rounded-pill bg-secondary item-badge">
        消耗
      </span>
    </div>
    <div class="col-6">
      <span class="badge rounded-pill bg-secondary item-badge">
        获得
      </span>
    </div>
    <template v-for="(exchange, index) in shop.exchange" :key="index">
      <div class="col-6">
        <div v-for="costItem in exchange.costItem" :key="costItem.item.id">
          <a :href="'#/item/' + costItem.item.id">
            <img :src="this.$root.getIconURL(costItem.item.icon)" class="rounded" width="24" height="24" />
          </a>
          <span class="ms-1 align-middle">{{ costItem.item.name }}
            <span v-if="costItem.isHQ == 1">(HQ)</span>x{{ costItem.count }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div v-for="receiveItem in exchange.receiveItem" :key="receiveItem.item.id">
          <a :href="'#/item/' + receiveItem.item.id">
            <img :src="this.$root.getIconURL(receiveItem.item.icon)" class="rounded" width="24" height="24" />
          </a>
          <span class="ms-1 align-middle">{{ receiveItem.item.name }}
            <span v-if="receiveItem.isHq == 1">(HQ)</span>x{{ receiveItem.count }}
          </span>
        </div>
      </div>
      <div class="col-12" v-if="index != shop.exchange.length - 1">
        <hr class="npc-divider" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ItemInfoExchange",
  props: ["shop"],
}
</script>

<style scoped>
.npc-divider {
  margin-top: 3px;
  margin-bottom: 3px;
}
</style>