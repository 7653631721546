<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">军票商店购买</span>

    <div class="btn-group mt-1 w-100 btn-group-sm" v-if="Object.keys(item.GCScripRecv).length == 3">
      <input type="radio" class="btn-check" name="gcshop" id="gcshop1" autocomplete="off" v-model="currentGCShop"
        :value="1">
      <label class="btn btn-outline-primary" for="gcshop1">黑涡团</label>

      <input type="radio" class="btn-check" name="gcshop" id="gcshop2" autocomplete="off" v-model="currentGCShop"
        :value="2">
      <label class="btn btn-outline-primary" for="gcshop2">双蛇党</label>

      <input type="radio" class="btn-check" name="gcshop" id="gcshop3" autocomplete="off" v-model="currentGCShop"
        :value="3">
      <label class="btn btn-outline-primary" for="gcshop3">恒辉队</label>
    </div>

    <div>
      <span class="badge item-info-badge me-1">NPC</span>
      <span class="align-middle">
        {{ item.GCScripRecv[currentGCShop].npc.name }}
        <span v-if="item.GCScripRecv[currentGCShop].npc.title != ''">
          &lt;{{ item.GCScripRecv[currentGCShop].npc.title }}&gt;
        </span>
      </span>
    </div>
    <div v-if="item.GCScripRecv[currentGCShop].npc.location != null">
      <span class="badge item-info-badge me-1">地图</span>
      <span class="align-middle">
        {{ item.GCScripRecv[currentGCShop].npc.location.map }}
        <span v-if="item.GCScripRecv[currentGCShop].npc.location.x != null">(X:
          {{ item.GCScripRecv[currentGCShop].npc.location.x }}, Y:
          {{ item.GCScripRecv[currentGCShop].npc.location.y }})</span>
      </span>
    </div>

    <div class="row">
      <div class="col-6">
        <span class="badge rounded-pill bg-secondary item-badge">
          物品
        </span>
      </div>
      <div class="col-3">
        <span class="badge rounded-pill bg-secondary item-badge">
          消耗
        </span>
      </div>
      <div class="col-3">
        <span class="badge rounded-pill bg-secondary item-badge">
          需要军衔
        </span>
      </div>
      <div class="col-6">
        <a :href="'#/item/' + item.id">
          <img :src="this.$root.getIconURL(item.icon)" class="rounded" width="24" height="24" />
        </a>
        <span class="ms-1 align-middle">{{ item.name }}</span>
      </div>
      <div class="col-3 text-center">
        <a :href="'#/item/' + item.GCScripRecv[currentGCShop].gcscrip.id">
          <img :src="this.$root.getIconURL(item.GCScripRecv[currentGCShop].gcscrip.icon)" class="rounded" width="24"
            height="24" />
        </a>
        <span class="ms-1 align-middle">{{ item.GCScripRecv[currentGCShop].cost }}</span>
      </div>
      <div class="col-3 text-center">
        <span class="align-middle">{{ item.GCScripRecv[currentGCShop].requiredRank }}</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ItemInfoGCShopRecv",
  props: ["item"],
  data() {
    return {
      currentGCShop: 1,
    };
  },
  created() {
    if (Object.keys(this.item.GCScripRecv).length < 3) {
      this.currentGCShop = Object.keys(this.item.GCScripRecv)[0];
    } else {
      this.currentGCShop = 1;
    }
  },
}
</script>

<style scoped>
.npc-divider {
  margin-top: 3px;
  margin-bottom: 3px;
}
</style>