<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">食物效果</span>
    <div class="col-12" v-if="item.foodAttr.hpPercent != null">
      <span class="badge item-info-badge me-1">HP</span>
      <span class="align-middle">恢复 {{ item.foodAttr.hpPercent }}% (上限
        {{ item.foodAttr.hpMax }})</span>
    </div>
    <div class="col-12" v-if="item.foodAttr.mpPercent != null">
      <span class="badge item-info-badge me-1">MP</span>
      <span class="align-middle">恢复 {{ item.foodAttr.mpPercent }}% (上限
        {{ item.foodAttr.mpMax }})</span>
    </div>
    <div class="d-block" v-for="effect in item.foodAttr.effect" :key="effect.name">
      <span class="badge item-info-badge me-1">{{ effect.baseParam }}</span>
      <span class="align-middle me-1">+{{ effect.value }}<span v-if="effect.isRelative == 1">% (上限 {{ effect.max
      }})</span>
      </span>
      <span class="badge bg-secondary me-1">HQ</span>
      <span class="align-middle">+{{ effect.valueHQ }}<span v-if="effect.isRelative == 1">% (上限 {{ effect.maxHQ
      }})</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemInfoFoodEffect",
  props: ["item"],
}
</script>