<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">
      收藏品缴纳
    </span>
    <div class="row">
      <div class="col-4">
        <span class="badge item-info-badge me-1">等级</span>
        <span class="align-middle">{{ item.collectableShop.maxLevel + getRecipeStar(item.collectableShop.stars)
        }}</span>
      </div>
      <div class="col-4">
        <span class="badge item-info-badge me-1">缴纳等级</span>
        <span class="align-middle">{{ item.collectableShop.minLevel }}</span>
      </div>
      <div class="col-4">
        <button type="button" class="btn item-info-btn btn-xs item-badge" @click="openCalcModal(item.id, item.name)"
          v-if="item.collectableShop.rewardType == 1">
          <b>升级计算器</b>
        </button>
      </div>
      <div class="col-4">
        <span class="badge rounded-pill item-info-badge item-badge">第一档</span>
        <span class="badge bg-secondary item-badge">所需收藏价值</span>
        <span class="d-block item-badge text-center">{{ item.collectableShop.refine.low }} ~
          {{ item.collectableShop.refine.mid - 1 }}</span>
        <span class="badge bg-secondary item-badge">奖励</span>
        <template v-if="item.collectableShop.rewardType == 1">
          <div class="item-badge text-center">
            <img :src="this.$root.getIconURL(item.collectableShop.reward.currency.icon)" class="rounded"
              :title="item.collectableShop.reward.currency.name" width="24" height="24" />
            <span class="align-middle">{{ item.collectableShop.reward.lowReward }}</span>
          </div>
          <div class="item-badge text-center">
            <img src="@/assets/exp.png" class="rounded" title="经验" width="24" height="24" />
            <span class="align-middle">{{ item.collectableShop.reward.expLow }}</span>
          </div>
        </template>

        <div class="item-badge text-center" v-else>
          <img :src="
            this.$root.getIconURL(
              item.collectableShop.reward.item.icon
            )
          " class="rounded" width="24" height="24" />
          <span class="ms-1 align-middle">{{ item.collectableShop.reward.item.name }}
            x{{ item.collectableShop.reward.low }}</span>
        </div>
      </div>

      <div class="col-4">
        <span class="badge rounded-pill item-info-badge item-badge">第二档</span>
        <span class="badge bg-secondary item-badge">所需收藏价值</span>
        <span class="d-block item-badge text-center">{{ item.collectableShop.refine.mid }} ~
          {{ item.collectableShop.refine.high - 1 }}</span>
        <span class="badge bg-secondary item-badge">奖励</span>
        <template v-if="item.collectableShop.rewardType == 1">
          <div class="item-badge text-center">
            <img :src="
            this.$root.getIconURL(item.collectableShop.reward.currency.icon)" class="rounded"
              :title="item.collectableShop.reward.currency.name" width="24" height="24" />
            <span class="align-middle">{{ item.collectableShop.reward.midReward }}</span>
          </div>
          <div class="item-badge text-center">
            <img src="@/assets/exp.png" class="rounded" width="24" height="24" />
            <span class="align-middle">{{ item.collectableShop.reward.expMid }}</span>
          </div>
        </template>

        <div class="item-badge text-center" v-else>
          <img :src="this.$root.getIconURL(item.collectableShop.reward.item.icon)" class="rounded" width="24"
            height="24" />
          <span class="ms-1 align-middle">{{ item.collectableShop.reward.item.name }}
            x{{ item.collectableShop.reward.mid }}</span>
        </div>
      </div>

      <div class="col-4">
        <span class="badge rounded-pill item-info-badge item-badge">第三档</span>
        <span class="badge bg-secondary item-badge">所需收藏价值</span>
        <span class="d-block item-badge text-center">{{ item.collectableShop.refine.high }} ~
        </span>
        <span class="badge bg-secondary item-badge">奖励</span>
        <template v-if="item.collectableShop.rewardType == 1">
          <div class="item-badge text-center">
            <img :src="
            this.$root.getIconURL(item.collectableShop.reward.currency.icon)" class="rounded"
              :title="item.collectableShop.reward.currency.name" width="24" height="24" />
            <span class="align-middle">{{ item.collectableShop.reward.highReward }}</span>
          </div>
          <div class="item-badge text-center">
            <img src="@/assets/exp.png" class="rounded" width="24" height="24" />
            <span class="align-middle">{{ item.collectableShop.reward.expHigh }}</span>
          </div>
        </template>

        <div class="item-badge text-center" v-else>
          <img :src="this.$root.getIconURL(item.collectableShop.reward.item.icon)" class="rounded" width="24"
            height="24" />
          <span class="ms-1 align-middle">{{ item.collectableShop.reward.item.name }}
            x{{ item.collectableShop.reward.high }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="collectable-calc" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">收藏品升级计算器</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>收藏品: {{ currentCalcItem.name }}</p>
          <p class="text-success" v-if="calcResult != null">
            所需数量: {{ calcResult.count }}, 可获得票据: {{ calcResult.scrip }}
          </p>
          <p class="text-muted">注: 不含制作获得经验</p>
          <hr />
          <div class="mb-3">
            <label for="start-level" class="form-label">初始等级</label>
            <input type="number" class="form-control" id="start-level" min="1" max="90" v-model="calcStartLevel" />
          </div>
          <div class="mb-3">
            <label for="target-level" class="form-label">目标等级</label>
            <input type="number" class="form-control" id="target-level" min="1" max="90" v-model="calcTargetLevel" />
          </div>
          <button type="button" class="btn btn-outline-primary d-block" style="width: 100%" @click="calc"
            :disabled="isCalculating ? 'disabled' : null">
            计算
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemInfoCollectableShop",
  props: ["item"],
  data() {
    return {
      isCalculating: false,
      currentCalcItem: {},
      calcResult: null,
      calcStartLevel: null,
      calcTargetLevel: null,
    };
  },
  methods: {
    getRecipeStar(starnum) {
      var string = "";

      for (let i = 0; i < starnum; i++) {
        string += "★";
      }

      return string;
    },
    openCalcModal(id, name) {
      this.currentCalcItem = {
        id: id,
        name: name,
      };

      this.calcResult = null;
      this.calcStartLevel = null;
      this.calcTargetLevel = null;

      var myModalEl = document.querySelector("#collectable-calc");
      var modal = this.bootstrap.Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
      modal.show();
    },
    calc() {
      this.isCalculating = true;

      var self = this;
      this.axios
        .post(this.apiBaseURL + "item.php?method=calcCollectableShopExp", {
          id: this.currentCalcItem.id,
          startLv: this.calcStartLevel,
          targetLv: this.calcTargetLevel,
        })
        .then(function (response) {
          self.isCalculating = false;

          if (response.data.success) {
            self.calcResult = {
              count: response.data.count,
              scrip: response.data.reward_scrip,
            };
          } else {
            alert("计算失败: " + response.data.error);
          }
        })
        .catch(function (error) {
          self.isCalculating = false;

          alert("计算失败: " + error);
        });
    },
  },
}

</script>