<template>
  <div class="d-block ingredient-gap" :class="secondary ? 'ms-3 ingredient-gap' : ''">
    <a :href="'#/item/' + item.id">
      <img :src="this.$root.getIconURL(item.icon)" class="rounded ingredient-icon" />
    </a>
    <span class="ms-1">{{ item.name }} x{{ item.amount }}</span>
    <span class="badge bg-primary ms-1" v-if="item.addedGameVer != null">
      {{ item.addedGameVer }}
    </span>
    <span class="badge bg-success ms-1" v-for="ctype in item.craft_type" :key="ctype">
      {{ getCraftType(ctype) }}
    </span>
    <span class="badge bg-secondary ms-1" v-if="item.gathering != null">
      {{ item.gathering.classJob.slice(0, 2) }}
      <span v-if="item.gathering.pointType != '普通'">
        ({{ item.gathering.pointType }}点)
      </span>
    </span>

    <span class="badge bg-secondary ms-1" v-if="item.shopPrice != null">
      {{ item.shopPrice }}G
    </span>
    <span class="badge bg-secondary ms-1" v-if="item.canExchange == true">
      兑换
    </span>
    <span class="badge bg-secondary ms-1" v-if="item.retainerTask == true">
      雇员
    </span>
    <ItemIngredient v-for="ingredient in item.ingredients" :key="ingredient.id" :item="ingredient" :secondary="true" />
  </div>
</template>

<script>
export default {
  name: "ItemIngredient",
  props: ["item", "secondary"],
  methods: {
    getCraftType(type) {
      type = parseInt(type);

      switch (type) {
        case 0:
          return "木工";
        case 1:
          return "锻冶";
        case 2:
          return "铸甲";
        case 3:
          return "雕金";
        case 4:
          return "制革";
        case 5:
          return "裁缝";
        case 6:
          return "炼金";
        case 7:
          return "烹调";
      }
    },
  },
};
</script>

<style scoped>
.ingredient-icon {
  width: 24px;
  height: 24px;
}

.ingredient-gap {
  margin-top: 1px;
}
</style>