import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

const app = createApp(App);

app.use(router);
app.config.globalProperties.axios = axios;
app.config.globalProperties.bootstrap = window.bootstrap;
//app.config.globalProperties.apiBaseURL = 'http://localhost/ff/api/';
//app.config.globalProperties.iconBaseURL = 'http://localhost/ff/';
app.config.globalProperties.apiBaseURL = './api/';
app.config.globalProperties.iconBaseURL = '.';
app.mount('#app');
