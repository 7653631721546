<template>
  <div class="card card-content" id="item-detail-card">
    <div class="card-body">
      <div class="row" v-if="item != null">
        <ItemInfoHeader :item="item"></ItemInfoHeader>
        <ItemInfoBasic :item="item"></ItemInfoBasic>
        <ItemInfoArmorAttr :item="item" v-if="item.itemSpecialBonus != null || item.itemAttr != null">
        </ItemInfoArmorAttr>
        <ItemInfoMateria :item="item" v-if="item.materia != null"></ItemInfoMateria>
        <ItemInfoFoodEffect :item="item" v-if="item.foodAttr != null"></ItemInfoFoodEffect>
        <ItemInfoGilShop :item="item" v-if="item.shopPrice != null"></ItemInfoGilShop>
        <ItemInfoGCShopCost :item="item" v-if="item.GCScripCost != null"></ItemInfoGCShopCost>
        <ItemInfoGCShopRecv :item="item" v-if="item.GCScripRecv != null"></ItemInfoGCShopRecv>
        <ItemInfoExchangeRecv :item="item" v-if="item.exchangeRecv != null"></ItemInfoExchangeRecv>
        <ItemInfoExchangeCost :item="item" v-if="item.exchangeCost != null"></ItemInfoExchangeCost>
        <ItemInfoGatheringPoint :item="item" v-if="item.gatheringPoint != null"></ItemInfoGatheringPoint>
        <ItemInfoRetainer :item="item" v-if="item.retainer != null"></ItemInfoRetainer>
        <ItemInfoRecipe :item="item"></ItemInfoRecipe>
        <ItemInfoCollectableShop :item="item" v-if="item.collectableShop != null"></ItemInfoCollectableShop>
      </div>

      <div class="col-12 text-center" v-else>
        <p class="text-center text-muted" v-if="loading">
          <span class="spinner-border spinner-border-sm text-primary"></span>
          加载中...
        </p>
        <p class="text-center" v-else :class="[hasError ? 'text-danger' : 'text-muted']">
          {{ errorMsg.length == 0 ? "请选择物品" : errorMsg }}
        </p>
      </div>
    </div>
  </div>


</template>

<script>
import ItemInfoHeader from "./ItemInfoHeader.vue";
import ItemInfoBasic from "./ItemInfoBasic.vue";
import ItemInfoArmorAttr from "./ItemInfoArmorAttr.vue";
import ItemInfoMateria from "./ItemInfoMateria.vue";
import ItemInfoFoodEffect from "./ItemInfoFoodEffect.vue";
import ItemInfoGilShop from "./ItemInfoGilShop.vue";
import ItemInfoExchangeRecv from "./ItemInfoExchangeRecv.vue";
import ItemInfoExchangeCost from "./ItemInfoExchangeCost.vue";
import ItemInfoRecipe from "./ItemInfoRecipe.vue";
import ItemInfoCollectableShop from "./ItemInfoCollectableShop.vue";
import ItemInfoRetainer from "./ItemInfoRetainer.vue";
import ItemInfoGatheringPoint from "./ItemInfoGatheringPoint.vue";
import ItemInfoGCShopCost from "./ItemInfoGCShopCost.vue";
import ItemInfoGCShopRecv from "./ItemInfoGCShopRecv.vue";

export default {
  name: "ItemSearchItemDetail",
  props: ["item", "loading", "hasError", "errorMsg"],
  components: {
    ItemInfoHeader,
    ItemInfoBasic,
    ItemInfoArmorAttr,
    ItemInfoMateria,
    ItemInfoFoodEffect,
    ItemInfoGilShop,
    ItemInfoExchangeRecv,
    ItemInfoExchangeCost,
    ItemInfoRecipe,
    ItemInfoCollectableShop,
    ItemInfoRetainer,
    ItemInfoGatheringPoint,
    ItemInfoGCShopCost,
    ItemInfoGCShopRecv,
  },
};
</script>

<style>
.card {
  overflow-y: auto;
}

.item-badge {
  width: 100%;
}

.item-info-badge {
  background-color: #11a3c0;
}

.item-info-btn {
  color: #fff;
  background-color: #1c6fbd;
  border-color: #1c6fbd;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 0.8;
}

.item-notice-badge {
  background-color: #1c6fbd;
}
</style>