<template>
  <div class="col-12">
    <span class="badge rounded-pill bg-secondary item-badge">
      装备属性
    </span>
    <div class="row" v-if="item.itemAttr != null">
      <div class="col-6" v-for="(stat, index) in item.itemAttr" :key="index">
        <span class="badge item-info-badge me-1">{{ stat.name }}</span>
        <span class="align-middle me-1">+{{ stat.nq }}</span>
        <span class="badge text-bg-secondary me-1" v-if="item.canBeHQ == 1">HQ</span>
        <span class="align-middle" v-if="item.canBeHQ == 1">+{{ parseInt(stat.nq) + parseInt(stat.hq) }}</span>
      </div>
    </div>

    <template v-if="item.itemSpecialBonus != null">
      <span class="badge rounded-pill bg-secondary item-badge">
        特殊效果 <span v-if="item.itemSeries != null">({{ item.itemSeries }})</span>
      </span>
      <div class="row">
        <div class="col-12">
          <span class="badge text-bg-secondary">{{ item.itemSpecialBonus.name }}
            <span v-if="item.itemSpecialBonus.level != null">{{
                item.itemSpecialBonus.level
            }} 级以下时生效</span>
          </span>
        </div>
      </div>
      <div class="row" v-if="item.itemSpecialBonus.attr != null">
        <div class="col-12" v-for="(stat, index) in item.itemSpecialBonus.attr" :key="index">
          <span class="badge item-info-badge me-1">{{ stat.name }}
            <span v-if="stat.index != null"> (装备 {{ parseInt(stat.index) + 2 }} 件以上)</span>
          </span>
          <span class="align-middle">{{
              stat.value.indexOf('-') > -1 ? stat.value.replace('-', '+') : "+" + stat.value
          }}</span>
        </div>
      </div>
    </template>

    <div class="col-12" v-if="item.materiaSlotCount > 0">
      <div class="row">
        <div class="col-6">
          <span class="badge item-info-badge me-1">魔晶石工艺</span>
          <span class="align-middle">{{ item.materiaSlotCount }} 个安全孔</span>
        </div>
        <div class="col-6">
          <span class="badge item-info-badge me-1">禁忌镶嵌</span>
          <span class="align-middle" v-if="item.isAdvancedMeldingPermitted == 1">
            <i class="bi bi-check-lg"></i>
          </span>
          <span class="align-middle" v-else>
            <i class="bi bi-x-lg"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemInfoArmorAttr",
  props: ["item"],
}
</script>