<template>
  <div class="card card-content">
    <div class="card-header">搜索结果</div>
    <div class="card-body" v-if="itemsList.length == 0">
      <p class="text-center text-muted" v-if="loading">
        <span class="spinner-border spinner-border-sm text-primary"></span>
        加载中...
      </p>
      <p
        class="text-center"
        v-else
        :class="[hasError ? 'text-danger' : 'text-muted']"
      >
        {{ errorMsg.length == 0 ? "无结果" : errorMsg }}
      </p>
    </div>
    <div class="list-group list-group-flush" v-else>
      <a
        v-for="item in itemsList"
        :key="item.id"
        :href="'#/item/' + item.id"
        class="list-group-item list-group-item-action item-list-item"
      >
        <img
          :src="this.$root.getIconURL(item.icon)"
          class="float-start me-2 item-icon rounded"
        />
        <div class="item-info">
          <span class="text-info me-1">{{ item.itemUICategory }}</span>
          <span class="text-secondary me-1">Lv.{{ item.equipLevel }}</span>
          <span class="text-secondary">ILv.{{ item.itemLevel }}</span>
        </div>
        <div class="item-name">
          {{ item.name == null ? item.name_en : item.name }}
        </div>
      </a>
    </div>
    <div class="card-footer text-center">
      <div class="row">
        <div class="col-4">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            @click="changePage(-1)"
            :disabled="currentPage == 1 || currentPage == 0 ? 'disabled' : null"
          >
            上一页
          </button>
        </div>
        <div class="col-4 text-muted">
          <span class="align-middle">{{ page }}</span>
        </div>
        <div class="col-4">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="changePage(1)"
            :disabled="
              currentPage == totalPages || currentPage == 0 ? 'disabled' : null
            "
          >
            下一页
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemSearchResult",
  props: {
    hasError: Boolean,
    errorMsg: String,
    itemsList: Array,
    loading: Boolean,
    currentPage: Number,
    totalPages: Number,
  },
  computed: {
    page() {
      if (this.itemsList.length > 0) {
        return this.currentPage + " / " + this.totalPages;
      } else {
        return "- / -";
      }
    },
  },
  methods: {
    changePage(direction) {
      this.$parent.itemSearchChangePage(direction);
    },
  },
};
</script>

<style scoped>
.item-icon {
  width: 40px;
  height: 40px;
}

.item-list-item {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-name {
  height: 20px;
}

.item-info {
  height: 18px;
  font-size: 12px;
  margin-top: -1px;
}

.card {
  overflow-y: hidden;
}

.list-group {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>